<template>
    <div>
        <el-card class="head_wrap">
            <div class="head fr">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        欢迎您{{ nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item command="1">个人中心</el-dropdown-item> -->
                        <el-dropdown-item command="2">修改密码</el-dropdown-item>
                        <el-dropdown-item command="3">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <p class="date fr">审稿系统</p>
            <div class="clear"></div>

        </el-card>
        <user-password ref="userPassword"></user-password>
    </div>
</template>

<script>
// import { get } from "../utils/http"
import { removeToken } from "@/utils/auth"
import UserPassword from './UserPassword'
export default {
    components: {
        UserPassword
    },
    data() {
        return {
            nickname: sessionStorage.getItem("nickname")
        }
    },
    //调接口
    mounted() {//组件挂在完毕执行内容

    },
    computed: {
    },
    methods: {
        handleCommand(command) {
            if (command == 3) {
                removeToken();
                this.$router.push('/login')
            } else if (command == 2) {
                this.$refs.userPassword.show(this.nickname)
            }

        }
    }
}
</script>

<style lang="less" scoped>
.head_wrap {
    // line-height: 70px;
    position: fixed;
    top: 0;
    // background-color: #ffffff;
    width: 100%;
    z-index: 100;
}

.el-dropdown-link {
    cursor: pointer;
}

.date {
    margin-right: 50px;
}

.head {
    margin-right: 30px;
}
</style>
<template>
    <div class="page">
        <headers />
        <el-card class="box-card">
            <p style="color: red; padding: 0;line-height: 30px; text-indent: 2em; width: 1000px;margin: auto;">
            </p>
            <div class="search-section">
                <div class="search-box">
                     <div class="searchItem">
                        <span>稿件ID：</span>
                        <el-input size="medium" class="search-content"
                            @keypress.enter.native="search" v-model="form.articleCustomId"
                            placeholder="请输入要查询稿件的ID"></el-input>
                    </div>
                   <div class="searchItem">
                        <span>期刊：</span>
                        <el-input size="medium" class="search-content" @keypress.enter.native="search"
                            v-model="form.journal" placeholder="请输入要查询的期刊名称"></el-input>
                   </div>
                </div>
                <div class="search-box button-box">
                    <el-button class="button" size="medium" type="primary" icon="el-icon-search"
                        @click="search">查询</el-button>
                    <el-button class="button" size="medium" type="primary" icon="el-icon-refresh-right"
                        @click="resetting">重置</el-button>
                </div>
                <div class="history" @click="showHistory">查看历史操作记录</div>
            </div>
            <div class='infoBox'>
                <i class='anticon anticon-info-circle ant-alert-icon'></i> 已选择 <a style='font-weight: 600'>{{
                            selectedNum
                        }}</a> 项
                <a style='margin-left: 24px' @click='toggleSelection()'>清空</a>
                <el-divider direction="vertical"></el-divider>
                <a @click='handleClickAll()'>批量通过</a>
            </div>
            <el-table ref="multipleTable" :data="tableData" border style="width: 100%" v-loading="loading"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="articleCustomId" label="稿件ID" min-width="150px">
                </el-table-column>
                <el-table-column prop="originalArticle" label="稿件" min-width="150px">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.originalArticle" type="text"
                            @click="downloadFile(scope.row.originalArticle)">下载稿件</el-button>
                        <span v-else style="font-style: italic;">无文件</span>
                    </template>
                </el-table-column>
                <el-table-column prop="journal" label="期刊" min-width="150px">
                </el-table-column>
                
                <el-table-column prop="duplicateCheckFile" label="查重报告" min-width="150px">

                    <template slot-scope="scope">
                        <el-button v-if="scope.row.duplicateCheckFile" type="text"
                            @click="downloadFile(scope.row.duplicateCheckFile)">下载查重报告</el-button>
                        <span v-else style="font-style: italic;">无文件</span>
                    </template>
                </el-table-column>
                <el-table-column prop="note" label="初审备注" min-width="150px">
                </el-table-column>
                <el-table-column prop="editorRemark" label="意见" min-width="150px">
                </el-table-column>
                <el-table-column prop="action" label="操作" min-width="150px" width="150">

                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row.id, 3)" type="text" size="medium">通过</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button @click="handleClick(scope.row.id, 2)" type="text" size="medium">驳回</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
                    layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </el-card>
        <fill-in-form ref="fillInForm" @ok="refresh"></fill-in-form>
        <history-list ref="historyList"></history-list>
    </div>
</template>

<script>
import { get, downFile } from '../utils/http'
import Vue from 'vue'
import Headers from '../components/Headers.vue'//引入组件
import fillInForm from './model/fillInForm'
import historyList from './model/historyList'
export default {
    components: {//进行注册才能用
        Headers, fillInForm, historyList
    },
    data() {
        return {
            searchValue: '',
            tableData: [],
            loading: false,
            total: 0,
            url: {
                list: '/oa/newArticleTask/queryEditorPageList',
            },
            selectedNum: 0,
            selected: [],
            currentPage: 1,
            parameter: {
                pageNo: 1,
                pageSize: 10,
                firstTrial: 0,
            },
            form: {}
        }
    },
    mounted() {
        // this.search()
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            get(this.url.list, this.parameter).then(res => {
                if (res.success) {
                    this.tableData = res.result.records
                    this.total = res.result.total
                } else {
                    this.$message.error(res.message)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        search() {
            Object.assign(this.parameter, this.form);
            this.getData()
        },
        resetting() {
            delete this.parameter.articleCustomId
            delete this.parameter.journal
            this.form = {}
            this.getData()
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.selected = val
            this.selectedNum = val.length
        },
        handleCurrentChange(currentPage) {
            this.parameter.pageNo = currentPage
            this.getData()
        },
        handleClick(id, index) {
            this.$refs.fillInForm.show(id, index)
        },
        handleClickAll() {
            if (this.selectedNum > 0) {
                let id = this.selected.map(item => item.id).join(',');
                console.log(id, 'id')
                this.handleClick(id, 3)
            } else {
                this.$message.warning('您没有选中任何一条数据！')
            }
        },
        refresh() {
            this.parameter.pageNo = 1
            this.getData()
        },
        showHistory() {
            this.$refs.historyList.show()
        },
        downloadFile(records) {
            let url = `/sys/common/static/` + records
            // 通过"/"分割字符串
            var parts = records.split('/');
            // 获取最后一个部分，即文件名部分
            var fileName = parts[parts.length - 1];
            return downFile(url, null).then((data) => {
                if (!data || data.size === 0) {
                    Vue.prototype['$message'].warning('文件下载失败')
                    return
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data]), fileName)
                } else {
                    let url = window.URL.createObjectURL(new Blob([data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link) //下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                }
            })
        }
    }

}
</script>

<style lang="less" scoped>
//lang="less"样式预处理器，scoped当前页面有效
.page {
    width: 100%;
    background-color: #e9eef3;
    min-height: calc(100vh - 88px);
    position: relative;
    padding-top: 70px;

    .box-card {
        width: 100%;

        .search-section {
            display: flex;
            // justify-content: center;
            margin-bottom: 20px;
            position: relative;

            .search-box {
                display: flex;
                align-items: center;
                margin-right: 20px;
                .searchItem{
                    display: flex;
                    align-items: center;
                    span {
                        flex-shrink: 0;
                    }

                    .search-content {
                        width: 100%;
                        width: 300px;
                        margin-right: 10px;
                    }
                }
                
            }

            .history {
                position: absolute;
                right: 20px;
                bottom: 50%;
                transform: translateY(-50%);
                font-size: 14px;
                color: #0093ee;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .infoBox {
            width: 100%;
            background-color: #e6f7ff;
            border: 1px solid #91d5ff;
            box-sizing: border-box;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            padding: 8px 15px 8px 37px;
            margin-bottom: 16px;
            border-radius: 3px;

            a {
                color: #0093ee;
                cursor: pointer;
            }
        }

        .pagination {
            width: calc(100% - 20px);
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
            margin-top: 30px;
        }


    }

}

@media screen and (max-width: 759px) {
    .page {
        width: 100%;

        .box-card {
            width: 100%;
            min-height: 600px;
            font-size: 14px;
            .search-section {

                flex-wrap: wrap;

                .search-box {
                    margin-bottom: 10px;
                    margin-right: 0;
                    width: 100%;
                    flex-wrap: wrap;
                    .searchItem{
                        margin-bottom: 10px;
                       span {
                            display: inline-block;
                            width: 70px;
                            text-align: right;
                        }
                        .search-content{
                            width: calc(100% - 70px);
                        }

                    }
                    
                    .button {
                        width: 40%;
                    }
                }
                .button-box{
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                }
                .history{
                    bottom: -10px;
                    transform: none;
                } 
            }



        }
    }
}


//vh把屏幕分100份1vh是1份</style>

<style>
.el-table td,
.el-table th {
    text-align: center !important;
}
</style>
<template>
    <el-dialog :width=popWidth title="已处理列表" :visible.sync="dialogTableVisible">
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
            <el-table-column label="序号" type="index" width="55">
            </el-table-column>
            <el-table-column prop="journal" label="期刊" min-width="150px">
            </el-table-column>
            <el-table-column prop="articleCustomId" label="稿件ID" min-width="150px">
            </el-table-column>
            <el-table-column prop="firstTrial" label="状态" min-width="150px">
                <template slot-scope="scope">
                    <span v-if="scope.row.firstTrial == 3" style="color: #67C23A">已通过初审</span>
                    <span v-else style="color: #F56C6C">未通过初审</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="originalArticle" label="稿件" min-width="150px">
                <template slot-scope="scope">
                    <el-button type="text" @click="downloadFile(scope.row.originalArticle)">下载稿件</el-button>
                </template>
            </el-table-column> -->
            <el-table-column prop="editorRemark" label="主编意见" min-width="150px">
            </el-table-column>

        </el-table>
        <div class="pagination">
            <el-pagination small background @current-change="handleCurrentChange" :current-page="currentPage"
                layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogTableVisible = false">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { get } from '@/utils/http'
export default {
    name: 'historyList',
    data() {
        return {
            list: '/oa/newArticleTask/queryEditorPageList',
            dialogTableVisible: false,
            tableData: [],
            currentPage: 1,
            loading: false,
            total: 0,
            pageNo: 1,
            popWidth:""
        }
    },
    mounted() {
        let screenWidth = window.innerWidth;
        if(screenWidth>=759){
            this.popWidth="50%"
        }else{
        this.popWidth="80%"
        }

    },
    methods: {
        show() {
            this.dialogTableVisible = true
            this.getList()
        },
        getList() {
            this.loading = true
            get(this.list, { pageNo: this.pageNo, pageSize: 10, firstTrial: 1 }).then(res => {
                if (res.success) {
                    this.tableData = res.result.records
                    this.total = res.result.total
                }
            }).finally(() => {
                this.loading = false
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage
            this.getList()
        },
    }
}
</script>

<style lang="less" scoped>
.pagination {
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 30px;
}
@media screen and (max-width: 759px) {
    .pagination{
        width: 100%;
        padding: 0;
    }
}
</style>
<template>
    <el-dialog title="填写意见" :visible.sync="dialogTableVisible">
        <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="loading">
            <el-form-item prop="remark">
                <el-input type="textarea" :rows="6" size="medium" placeholder="请输入您的意见" v-model="form.remark" />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="changePassword">确 定</el-button>
        </div>
    </el-dialog>
</template>
  
<script>
import { get } from "@/utils/http"
export default {
    name: "fillInForm",
    data() {
        return {
            dialogTableVisible: false,
            form: {},
            firstTrial: '/oa/newArticleTask/firstTrial',
            rules: {
            },
            loading: false
        }
    },
    mounted() {
    },
    methods: {
        show(id, index) {
            this.form.taskId = id
            this.form.firstTrialStatus = index
            this.dialogTableVisible = true;
        },
        changePassword() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.loading = true
                    get(this.firstTrial, this.form).then(res => {
                        if (res.success) {
                            this.$message.success(res.message)
                            this.form = {}
                            this.dialogTableVisible = false
                            this.$emit('ok')
                        } else {
                            this.$message.error(res.message)
                        }
                    }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage
            this.getData()
        }
    }
}
</script>
  
<style scoped></style>
  
  
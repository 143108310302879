<template>
  <el-dialog :width=popWidth title="修改密码" :visible.sync="dialogTableVisible">
    <el-form :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth" v-loading="loading">
      <el-form-item label="旧密码" prop="oldpassword">
        <el-input size="medium" class="input-with-select" type="password" placeholder="请输入旧密码"
          v-model="form.oldpassword" />
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input size="medium" class="input-with-select" type="password" placeholder="请输入新密码"
          v-model="form.password" />
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmpassword">
        <el-input size="medium" class="input-with-select" type="password" placeholder="请确认密码"
          v-model="form.confirmpassword" />
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="changePassword">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { getToken } from '../utils/auth'

import { put } from "../utils/http"
export default {
  name: "UserPassword",
  data() {
    return {
      dialogTableVisible: false,
      form: {},
      formLabelWidth: '30%',
      url: "sys/user/updatePassword",
      rules: {
        oldpassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { required: true, pattern: /^.{8,}$/, message: '请输入由8位新密码！' },
          { validator: this.validateToNextPassword, trigger: 'blur' }
        ],
        confirmpassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: this.compareToFirstPassword, }
        ]
      },
      popWidth:"",
      loading: false
    }
  },
  mounted() {
    // console.log(getToken())
    let screenWidth = window.innerWidth;
    if(screenWidth>=759){
        this.popWidth="50%"
    }else{
      this.popWidth="80%"
    }


  },
  methods: {
    show(uname) {
      if (!uname) {
        this.$message.warning("当前系统无登录用户!");
        return
      } else {
        this.form.username = sessionStorage.getItem("nickname")
        // this.form.resetFields();
        this.dialogTableVisible = true;
      }
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.form.password) {
        callback('两次输入的密码不一样！');
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const confirmpassword = this.form.confirmpassword;
      if (value && confirmpassword && value !== confirmpassword) {
        callback('两次输入的密码不一样！');
      }
      if (value && this.confirmDirty) {
        this.$refs.form.validateField(['confirmpassword']);
      }
      callback();
    },
    changePassword() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true
          put(this.url, this.form).then(res => {
            if (res.success) {
              this.$message.success(res.message)
              this.form = {}
              this.dialogTableVisible = false
            } else {
              this.$message.error(res.message)
            }
          }).catch(err => {
            this.$message.error(err.message)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    close(){
      this.dialogTableVisible=false
    }

  }
}
</script>

<style lang="less" scoped>
.input-with-select {
  width: 60%;
}

@media screen and (max-width: 759px) {
  .input-with-select {
    width: 85%;
  }
}
</style>
